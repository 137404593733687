import {apiSlice} from "./api";
import {ProspectingRelation, ProspectingTemplate, ProspectingUser} from "@/lib/types";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    createProspectingTemplate: builder.mutation<ProspectingTemplate, {
      object: string,
      content: string,
      name: string,
      id?: string
    }>({
      query: (data) => ({
        url: `/users/prospecting/templates`, method: 'post', body: data
      }),
    }),
    createProspectingUser: builder.mutation<ProspectingUser, {
      email: string,
      display_name?: string,
      website?: string,
      comment?: string
    }>({
      query: (data) => ({
        url: `/users/prospecting/users`, method: 'post', body: data
      }),
    }),
    createProspectingRelation: builder.mutation<ProspectingRelation, { user_id: string, template_id: string }>({
      query: (data) => ({
        url: `/users/prospecting/relations`, method: 'post', body: data
      }),
    }),
  })), overrideExisting: false
})


export const {
  useCreateProspectingTemplateMutation,
  useCreateProspectingUserMutation,
  useCreateProspectingRelationMutation
} = extendedApi
