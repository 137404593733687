import {LodgingBooking, LodgingHead, Media, ResultList} from "@/lib/types";
import {apiSlice} from "./api";

export interface CreateLodging {
  "title": string
  "zipcode": string
  "city": string,
  "description": string,
  "type": string,
  "persons": number,
  "equipments": string[],
  "pets": string[],
  "bookingUrl": string,
  "lowPrice": number,
  "highPrice": number,
  "street": string,
  "email": string,
  "phone": string
}


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    getMyRooms: builder.query<ResultList<LodgingHead>, void>({
      query: () => ({url: `/lodgings/search?owner=true&cache=false`, method: 'post', body: {}}),
    }),
    createRoom: builder.mutation<{ lodgingId: string }, CreateLodging>({
      query: (data) => ({
        url: `/lodgings/`, method: 'post', body: data
      }),
    }),
    updateRoom: builder.mutation<{ lodgingId: string }, { id: string } & CreateLodging>({
      query: ({id, ...rest}) => ({
        url: `/lodgings/${id}`, method: 'put', body: rest
      }),
    }),
    getRoomMedia: builder.mutation<Media[], { id: string }>({
      query: ({id}) => `/lodgings/${id}/media`,
    }),

    getToModerateRooms: builder.query<LodgingHead[], void>({
      query: () => `/lodgings/admin/moderate`,
    }),
    changeRoomStatus: builder.mutation<void, { roomId: string, status: string, reason?: string }>({
      query: ({roomId, ...rest}) => ({
        url: `/lodgings/${roomId}/status`, method: 'put', body: rest
      }),
    }),

    publishRoom: builder.mutation<void, { id: string }>({
      query: ({id}) => ({
        url: `/lodgings/${id}/publish`, method: 'put', body: {}
      }),
    }),
    deactivateRoom: builder.mutation<void, { id: string, reason: string, reason_message?: string }>({
      query: ({id, ...rest}) => ({
        url: `/lodgings/${id}/delete`, method: 'put', body: rest
      }),
    }),

    getLodgingBookings: builder.query<LodgingBooking[], { id: string }>({
      query: ({id}) => `/lodgings/${id}/bookings`,
    }),
    createLodgingBooking: builder.mutation<void, Partial<LodgingBooking>>({
      query: ({lodging_id, ...rest}) => ({
        url: `/lodgings/${lodging_id}/bookings`, method: 'post', body: rest
      }),
    }),
    removeLodgingBooking: builder.mutation<void, { id: string, lodging_id: string }>({
      query: ({id, lodging_id}) => ({
        url: `/lodgings/${lodging_id}/bookings/${id}`, method: 'delete'
      }),
    }),

  })), overrideExisting: false
})


export const {
  useGetLodgingBookingsQuery,
  useCreateLodgingBookingMutation,
  useRemoveLodgingBookingMutation,
  useGetMyRoomsQuery,
  useCreateRoomMutation,
  useUpdateRoomMutation,
  usePublishRoomMutation,
  useDeactivateRoomMutation,
  useGetRoomMediaMutation,
  useGetToModerateRoomsQuery,
  useChangeRoomStatusMutation,
} = extendedApi
