import {apiSlice} from "./api";
import {EventCategory, Media, ResultList} from "@/lib/types";

export interface CreateEvent {
  title: string,
  content: string,
  meta_title?: string,
  summary?: string,
  website?: string,
  begin_date?: string,
  category_id?: string,
  end_date?: string,
  next_id?: string,
  preview_id?: string,
  parent_id?: string,
}


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    getMyEvents: builder.query<ResultList<any>, void>({
      query: () => ({url: `/events/search?owner=true&cache=false`, method: 'post', body: {}}),
    }),
    createEvent: builder.mutation<{ id: string }, CreateEvent>({
      query: (data) => ({
        url: `/events/`, method: 'post', body: data
      }),
    }),
    updateEvent: builder.mutation<{ id: string }, { id: string } & CreateEvent>({
      query: ({id, ...rest}) => ({
        url: `/events/${id}`, method: 'put', body: rest
      }),
    }),
    getEventMedias: builder.mutation<Media[], { id: string }>({
      query: ({id}) => `/events/${id}/media`,
    }),
    getEventCategories: builder.query<EventCategory[], void>({
      query: () => '/events/categories',
    }),


  })), overrideExisting: false
})


export const {
  useGetMyEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useGetEventCategoriesQuery,
  useGetEventMediasMutation,
} = extendedApi
