import {apiSlice} from "@/lib/client/slices"
import {serialize} from "@/lib/tools/strings";
import {AccountType, BillingOption, BillingOptionSubscription, ObjectType, PaymentMethod} from "@/lib/types";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBillingOptions: builder.query<BillingOption[], {
      type: AccountType,
      object_type: ObjectType
    }>({
      query: (opts) => ({url: `/billing/options?${serialize(opts)}`, method: "get"}),
    }),
    getBillingOptionSubscriptions: builder.query<BillingOptionSubscription[], {
      object_id: string,
      object_type: ObjectType
    }>({
      query: (opts) => ({url: `/billing/options/subscriptions`, method: "post", body: opts}),
    }),
    getBillingOptionSubscriptionCheckout: builder.query<{
      first_intent: { total_amount: number },
      next_intents: { total_amount: number }
    }, {
      object_id: string,
      object_type: ObjectType
    }>({
      query: (opts) => ({url: `/billing/options/checkout`, method: "post", body: opts}),
    }),
    getBillingPaymentMethods: builder.query<PaymentMethod[], { id: string }>({
      query: ({id}) => ({url: `/billing/customers/${id}/payment-method`, method: "get"}),
    }),
    getBillingPayments: builder.query<any[], { objectId: string, objectType: string }>({
      query: ({objectId, objectType}) => ({
        url: `/billing/payments/intents/get`, method: 'post', body: {object_id: objectId, object_type: objectType}
      }),
    }),
    processPayment: builder.mutation<void, { id: string }>({
      query: ({id}) => ({
        url: `/billing/payments/intents/${id}/confirm`, method: 'post', body: {}
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBillingOptionSubscriptionCheckoutQuery,
  useGetBillingOptionsQuery,
  useGetBillingPaymentMethodsQuery,
  useGetBillingOptionSubscriptionsQuery,
  useGetBillingPaymentsQuery,
  useProcessPaymentMutation,
} = extendedApi;
  