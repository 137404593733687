import {Feedback} from "@/lib/types";
import {apiSlice} from "./api";
import {serialize} from "@/lib/tools/strings";


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    sendFeedback: builder.mutation<void, {
      description: string
      name: string
      medium: string
      source: string
      term: string
      type: string
    }>({
      query: (body) => ({
        url: '/feedbacks/create',
        method: 'post',
        body: body
      })
    }),
    getFeedbacks: builder.mutation<Feedback[], {
      status?: string,
      name?: string,
      comment?: string,
      term?: string,
      source?: string,
      medium?: string,
      limit?: number,
      order_by?: string,
      order_direction?: string
    }>({
      query: (body) => `/feedbacks/${serialize(body)}`,
    }),
  })), overrideExisting: false
})


export const {
  useSendFeedbackMutation,
  useGetFeedbacksMutation
} = extendedApi