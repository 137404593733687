import {apiSlice} from "./api";
import {ServiceOffer} from "@/lib/types";

export interface ServiceForm {
  title: string
  description: string
  city_id: string
  type: string
  id?: string
}


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    createService: builder.mutation<{ id: string }, ServiceForm>({
      query: (data) => ({
        url: `/services/`, method: 'post', body: data
      }),
    }),
    updateService: builder.mutation<{ id: string }, ServiceForm>({
      query: ({id, ...rest}) => ({
        url: `/services/${id}`, method: 'put', body: rest
      }),
    }),
    publishService: builder.mutation<void, { id: string }>({
      query: ({id}) => ({
        url: `/services/${id}/publish`, method: 'put', body: {}
      }),
    }),
    deleteService: builder.mutation<void, { id: string, reason: string, reason_message?: string }>({
      query: ({id, ...rest}) => ({
        url: `/services/${id}`, method: 'delete', body: rest
      }),
    }),

    getServiceOffers: builder.query<ServiceOffer[], { id: string }>({
      query: ({id}) => `/services/${id}/offers`,
    }),

    createServiceOffer: builder.mutation<void, {
      service_id: string,
      pet_id: string,
      amount: number,
      comment?: string
    }>({
      query: ({service_id, ...rest}) => ({
        url: `/services/${service_id}/offers`, method: 'post', body: rest
      }),
    }),
    removeServiceOffer: builder.mutation<void, { id: string, service_id: string }>({
      query: ({id, service_id}) => ({
        url: `/services/${service_id}/offers/${id}`, method: 'delete'
      }),
    }),
    changeServiceStatus: builder.mutation<void, { serviceId: string, status: string, reason?: string }>({
      query: ({serviceId, ...rest}) => ({
        url: `/services/${serviceId}/status`, method: 'put', body: rest
      }),
    }),


  })), overrideExisting: false
})


export const {
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  usePublishServiceMutation,
  useGetServiceOffersQuery,
  useCreateServiceOfferMutation,
  useRemoveServiceOfferMutation,
  useChangeServiceStatusMutation,
} = extendedApi
