import {WebsiteConfiguration} from "@/lib/types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SystemState {
  menu: MenuState;
  location: string;
}

const initialState: SystemState = {
  menu: {opened: false, searchTerm: ''},
  location: "window.location.pathname"
};

export interface MenuState {
  opened: boolean;
  searchTerm: string;
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    information(state, action: PayloadAction<{ configuration: WebsiteConfiguration, statistics: any }>) {
      // state.configuration = action.payload.configuration
    },
    locationChange(state, action: PayloadAction<{ location: string }>) {
      state.location = action.payload.location
    },
  },
})

export const {information, locationChange} = appSlice.actions
export default appSlice.reducer