import {apiSlice} from "./api";
import {UserProfile} from "../../types/accounts";

export interface SignUpInput {
  "display_name": string,
  "email": string,
  "password": string,
  "confirm_password": string,
  "token": string,
  "cgu": boolean,
  "organization"?: {
    "siren"?: string
  }
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => (
    {
      getProfile: builder.mutation<UserProfile, void>({
        query: () => `/iam/account/me`,
      }),

      updateProfile: builder.mutation<UserProfile, any>({
        query: (data) => ({
          url: `/iam`, method: 'put', body: data
        }),
      }),
      changeAccount: builder.mutation<void, { id: string }>({
        query: (data) => ({
          url: `/iam/accounts/change`, method: 'post', body: data
        }),
      }),
      updateAccount: builder.mutation<void, { id: string, display_name?: string }>({
        query: ({id, ...data}) => ({
          url: `/iam/accounts/${id}`, method: 'put', body: data
        }),
      }),
      updateProfilePassword: builder.mutation<UserProfile, {
        id: string,
        oldPassword: string,
        newPassword: string,
        confirmNewPassword: string
      }>({
        query: (data) => ({
          url: `/iam/${data.id}/password/change`,
          method: 'put',
          body: {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
            confirmNewPassword: data.confirmNewPassword
          }
        })
      }),
      logIn: builder.mutation<UserProfile, { email: string, password: string, token: string }>({
        query: (data) => ({
          url: `/iam/login`, method: 'post', body: data,
        }),
      }),
      logout: builder.mutation<void, void>({
        query: (data) => ({
          url: `/iam/logout`, method: 'post',
          responseHandler: (response) => {
            removeAuthorizationToken()
            return response.json();
          }
        }),
      }),

      accountRecovery: builder.mutation<void, { email: string, token: string }>({
        query: (data) => ({url: `/iam/password/reset`, method: 'post', body: data}),
      }),

      confirmAccountRecovery: builder.mutation<void, {
        code: string,
        email: string,
        password: string,
        confirm_password: string,
        token: string
      }>({
        query: (data) => ({url: `/iam/password/confirm`, method: 'post', body: data}),
      }),

      confirmAccount: builder.mutation<void, { code: string, email: string, token: string }>({
        query: (data) => ({url: `/iam/email/confirm`, method: 'post', body: data}),
      }),

      resetConfirmAccountCode: builder.mutation<void, {  email: string, token: string }>({
        query: (data) => ({url: `/iam/email/confirm/resend`, method: 'post', body: data}),
      }),

      signup: builder.mutation<void, SignUpInput>({
        query: (data) => ({url: `/iam/register`, method: 'post', body: data}),
      }),

      logInOauth: builder.mutation<UserProfile, { provider: string, tokenId: string }>({
        query: (data) => ({
          url: `/iam/oauth/${data.provider}`, method: 'post', body: {tokenId: data.tokenId},
          responseHandler: (response) => {
            StoreHttpAuthorizationToken(response)
            return response.json();
          }
        }),
      }),

    }
  )),
  overrideExisting: false
})


function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const StoreHttpAuthorizationToken = (res: Response) => {
  const token: string | null = res.headers.get("authorization") || res.headers.get("Authorization");
  if (token != null && token.toLowerCase().startsWith("bearer ")) {
    // setAuthorizationToken(token);
  }
};

export const removeAuthorizationToken = (): Promise<void> => {
  eraseCookie('sid_lppn')
  return Promise.resolve()
}

export const {
  useGetProfileMutation,

  useLogInMutation,
  useLogoutMutation,
  useLogInOauthMutation,
  useUpdateProfileMutation,
  useUpdateProfilePasswordMutation,
  useAccountRecoveryMutation,
  useConfirmAccountRecoveryMutation,
  useSignupMutation,
  useChangeAccountMutation,
  useUpdateAccountMutation,
  useConfirmAccountMutation,
  useResetConfirmAccountCodeMutation,
} = extendedApi
