import {serialize} from "@/lib/tools/strings";
import {apiSlice} from "./api";


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    getPlaces: builder.mutation<any, { [key: string]: any }>({
      query: (data) => ({
        url: `/locations/?limit=1000&${serialize(data)}`, method: 'get'
      }),
    }),

    createPlaceComment: builder.mutation<void, { id: string, rating: number, content: string }>({
      query: ({id, ...rest}) => ({url: `/locations/${id}/comments`, method: 'post', body: rest}),
    }),
  })), overrideExisting: false
})


export const {
  useGetPlacesMutation,
  useCreatePlaceCommentMutation,
} = extendedApi
