import {apiSlice} from "./api";

export interface CreatePage {
  title: string,
  content: string,
  meta_title: string,
  summary: string,
  next_page_id: string,
  preview_page_id: string,
}


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    createPage: builder.mutation<{ id: string }, CreatePage>({
      query: (data) => ({
        url: `/pages/`, method: 'post', body: data
      }),
    }),
    updatePage: builder.mutation<{ id: string }, { id: string } & CreatePage>({
      query: ({id, ...rest}) => ({
        url: `/pages/${id}`, method: 'put', body: rest
      }),
    }),
    deletePage: builder.mutation<void, { id: string, reason: string, reason_message?: string }>({
      query: ({id, ...rest}) => ({
        url: `/pages/${id}`, method: 'delete', body: rest
      }),
    }),
    publishPage: builder.mutation<void, { id: string }>({
      query: ({id}) => ({
        url: `/pages/${id}/publish`, method: 'put', body: {}
      }),
    }),
  })), overrideExisting: false
})


export const {
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
  usePublishPageMutation,
} = extendedApi
